import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Housing from '../../assets/images/Housing.png'
import Grid from '@material-ui/core/Grid';
import './Footer.css'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: "black"

    },
}));

export default function Footer() {


    const classes = useStyles();
    function Copyright() {
        return (
            <React.Fragment>
                {'© '}
                <Link color="inherit" href="https://mui.com/">
                    Your Website
                </Link>{' '}
                {new Date().getFullYear()}
            </React.Fragment>
        );
    }
    return (
        <Fragment>
            <CssBaseline />
            <Grid container className="footerContainer" >
                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <Link className="footer_para" variant="h6" href="https://mui.com/" style={{ color: "#ccc" }}>
                            Synergy One Lending, Inc. © {new Date().getFullYear()} | Privacy Policy | Licensing
                        </Link>{' '}


                        <Grid item>
                            <div style={{ display: 'flex', justifyContent: "center" }} className="footer_paratwo">
                                <Typography className="footer_para" variant="h6" gutterBottom >
                                    <Link href="https://www.freepik.com" rel="sponsored" title="Freepik" style={{ color: "#ccc" }}>
                                        NMLS  Consumer Access | Synergy One Lending is an Equal Opportunity Employer EEO is the law.
                                    </Link>
                                </Typography>
                                <img src={Housing} alt="housing" className="housing" />

                            </div>


                        </Grid>


                    </Paper>

                </Grid>

            </Grid>
        </Fragment>

    );
}
