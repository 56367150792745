import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { getLoanDetails } from "services";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { SYSTEM_ERROR } from "../../config/CONSTANTS";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px #87CEFA",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const LoanLookupView = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loanDetails, setLoanDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const onSubmit = (data) => {
    var loanNumber = data.loanNumber;
    var lastFourDigitsOfSSN = data.ssn;

    getLoanDetails(lastFourDigitsOfSSN, loanNumber)
      .then((res) => {
        setLoanDetails(res);
        console.log(res, "res123");
        if (parseInt(res.loanDetailId) != 0) {
          history.push({
            pathname: "/LoanLookupShowData",
            state: { loanDetails: res },
          });
        } else {

          Swal.fire(
            'Loan does not exist.',
            '',
            'error'
          )
          //return history.push("/NotFound");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          SYSTEM_ERROR,
          'some',
          'error'
        )        
        setLoanDetails({});
        setIsLoading(false);
      });
  };
  console.log(errors);

  return (
    <div className={classes.paper} onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h1" variant="h5">
        Loan Lookup
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          {...register("loanNumber", { required: "LoanNumber is required." })}
          error={Boolean(errors.loanNumber)}
          helperText={errors.loanNumber?.message}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="loanNumber"
          label="Loan Number"
          name="loanNumber"
          autoComplete="loanNumber"
          autoFocus
        />
        <TextField
          {...register("ssn", {
            required: "Last four digits of SSN is required.",
          })}
          error={Boolean(errors.ssn)}
          helperText={errors.ssn?.message}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="ssn"
          label="Last four digits of SSN"
          type="text"
          id="ssn"
          autoComplete="Last four digits of SSN"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Look up
        </Button>
      </form>
    </div>
  );
};
