import React, { useEffect, useState } from "react";
import { LoanLookupView } from "./LoanLookupView";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import { ROOT } from "navigation/CONSTANTS";
import { CircularProgress, Typography } from "@material-ui/core";
import { getLoanDetails } from "services";
import { getData } from "../../services/Fetch_Service";

export const LoanLookupContainer = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <LoanLookupView />
    </Container>
  );
};
