import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import { ROOT } from "navigation/CONSTANTS";
import { CircularProgress, Typography } from "@material-ui/core";
import { getLoanDetails } from "services";

import { getData } from "../../services/Fetch_Service";
import Paymentform from "./paymentform";
import Header from '../CommonComponents/Header'
import Footer from '../CommonComponents/Footer'

export const PaymentformContainer = () => {

    useEffect(() => {

        // TODO: get the values from the form on click of submit
        return () => {
            console.log("axios cleanup.");
        };
    }, []);

    return (
        <Container maxWidth="lg" style={{ marginTop: 20, marginBottom: 20 }}>
            <CssBaseline />
            <Header />
            <Paymentform />

            <Footer />

        </Container>
    );
};
