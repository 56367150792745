export const ROOT = "/";
export const LOGIN = "/login";
export const LOAN_LOOKUP = "/loan-lookup";
export const DASHBOARD = "/dashboard";
export const PAGE1 = "/page1";
export const AUTH_PAGE1 = "/authorized1";
export const LoanLookupShowData = "/LoanLookupShowData";
export const Login_PAGE1 = "/loginpage";
export const HEADER = "/header";
export const FOOTER = "/footer";
export const PAYMENTRESULT = "/paymentresult";
