import React from "react";
import { Switch, Route } from "react-router-dom";
import LoanLookup from "pages/LoanLookup";
import LoanLookupShow from "pages/LoanLookup/LoanLookupShowData";

import { NotFound } from "navigation/NotFound";
import {
  ROOT,
  LOAN_LOOKUP,
  LoanLookupShowData,
  Login_PAGE1,
  HEADER,
  FOOTER,
  PAYMENTRESULT,
} from "navigation/CONSTANTS";
import Login from "./Auth/Login";
import Paymentform from "pages/PaymentForm/paymentform";
import LoginPage from "pages/LoginScreen/LoginPage";
import CheckRedux from "pages/LoginScreen/checkRedux";

import Header from "pages/CommonComponents/Header";
import Footer from "pages/CommonComponents/Footer";
import PaymentSuccessPage from "pages/PaymentForm/PaymentSuccessPage";
import { PaymentformContainer } from "pages/PaymentForm/PaymentformContainer";

export const RouterConfig = (props) => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} component={LoginPage} />
        <Route exact path={LOAN_LOOKUP} component={LoanLookup} />
        <Route exact path={LoanLookupShowData} component={LoanLookupShow} />
        <Route exact path={Login_PAGE1} component={LoginPage} />
        <Route exact path={HEADER} component={Header} />
        <Route exact path={FOOTER} component={Footer} />
        <Route exact path={FOOTER} component={Footer} />
        <Route exact path={PAYMENTRESULT} component={PaymentSuccessPage} />
        <Route exact path="/CheckRedux" component={CheckRedux} />

        <Route exact path="/Paymentform" component={Paymentform} />

        <Route path="/login">
          <Login />
        </Route>
        <Route path="/loan-lookup">
          <Login />
        </Route>

        <Route path="/PaymentformContainer">
          <PaymentformContainer />
        </Route>
        {/* List all private/auth routes here */}
        {/* <PrivateRoute path={AUTH_PAGE1}>
          <AuthorizedPage1 />
        </PrivateRoute> */}
        {/* Do not hesitate to play around by moving some routes from public to private and vice-versa */}
        {/* <PrivateRoute path={DASHBOARD}>
          <Dashboard />
        </PrivateRoute> */}

        {/* List a generic 404-Not Found route here */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};
