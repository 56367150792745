import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

export const FormInputText = ({
  name,
  control,
  label,
  rows,
  multiline,
  ref,
  disabled,
  inputpropdata,
  handleCopy,
  handlePaste,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState: { errorr },
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          minRows={rows}
          multiline
          inputRef={ref}
          autoFocus
          disabled={disabled}
          InputProps={inputpropdata}
          onPaste={handlePaste}
          onCopy={handleCopy}
          id={label}
        />
      )}
    />
  );
};
