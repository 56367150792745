import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const BorrowersByLoanForm = (props) => {
  const { classes, register, errors } = props;
  return (
    <form className={classes.form} noValidate>
      <TextField
        {...register("loanNumber", {
          required: "LoanNumber is required.",
        })}
        error={Boolean(errors.loanNumber)}
        helperText={errors.loanNumber?.message}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="loanNumber"
        label="Loan Number"
        name="loanNumber"
        autoComplete="loanNumber"
        autoFocus
      />
      <TextField
        {...register("ssn", {
          required: "Last four digits of SSN is required.",
        })}
        error={Boolean(errors.ssn)}
        helperText={errors.ssn?.message}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="ssn"
        label="Last four digits of SSN"
        type="text"
        id="ssn"
        autoComplete="Last four digits of SSN"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Look up
      </Button>
    </form>
  );
};

export default BorrowersByLoanForm;
