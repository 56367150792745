import React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

const options = [
    {
        label: "Radio Option 1",
        value: "1",
    },
    {
        label: "Radio Option 2",
        value: "2",
    },
];

export const FormInputRadio = ({
    name,
    control,
    label,
    options
}) => {
    const generateRadioOptions = () => {
        return options.map((singleOption, index) => (
            <div key={index} className="">

                <FormControlLabel
                    value={singleOption.value}
                    label={singleOption.label}
                    control={<Radio />}
                    style={{ margin: "0.5rem", border: "2px soild red" }}
                    className=""
                />
            </div>
        ));
    };

    return (
        <FormControl component="fieldset" className="">
            <FormLabel component="legend" className="">{label}</FormLabel >
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                    formState,
                }) => (
                    <RadioGroup value={value} onChange={onChange} className="" row={true}>
                        {generateRadioOptions()}
                    </RadioGroup>
                )}
            />
        </FormControl>
    );
};