import React from 'react';
import { USER_LOGIN_SUCCESS } from '../../redux/actions/loginActions';
import { useDispatch, useSelector } from "react-redux";

export default function CheckRedux() {

    const dispatch = useDispatch();

    const handleSubmit = () => {
        let body = {
            "loanNumber": "123456789",
            "ssn": "123456789"
        }
        dispatch(USER_LOGIN_SUCCESS(body));
    }
    return (
        <div><button onClick={handleSubmit}>
            click here
        </button></div>
    )
}
