import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import s1llogo from '../../assets/images/s1llogo.png';
import Avatar from '@material-ui/core/Avatar'

import './Header.css'
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        // border: "2px solid red",
        marginBottom: 15


    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function DenseAppBar() {
    const classes = useStyles();

    return (
        <Fragment>
            <CssBaseline />
            <div className={classes.root} >
                <AppBar position="static" color="inherit" elevation={3}>
                    <Toolbar variant="dense">
                        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton> */}
                        <Link to='/'>
                            <img src={s1llogo} alt="s1llogo" className='s1llogoHeader' />
                        </Link>
                        {/* <Typography variant="h6" color="inherit">
                            Photos
                        </Typography> */}
                    </Toolbar>
                </AppBar>
            </div>
        </Fragment>
    );
}
