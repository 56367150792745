const initialState = {
  userDetails: {},
  loanAndBorrowers: {},
  borrowerAndLoans: {},
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "GET_LOAN_AND_BORROWERS":
      return {
        ...state,
        loanAndBorrowers: action.payload,
        borrowerAndLoans: {},
      };
    case "GET_BORROWER_AND_LOANS":
      return {
        ...state,
        loanAndBorrowers: {},
        borrowerAndLoans: action.payload,
      };
    default:
      return state;
  }
};
