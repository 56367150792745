const USER_LOGIN_SUCCESS = (data) => {
  return {
    type: "USER_LOGIN_SUCCESS",
    payload: data,
  };
};

const LOAN_AND_BORROWERS = (data) => {
  return {
    type: "GET_LOAN_AND_BORROWERS",
    payload: data,
  };
};

const BORROWER_AND_LOANS = (data) => {
  return {
    type: "GET_BORROWER_AND_LOANS",
    payload: data,
  };
};

export { LOAN_AND_BORROWERS, BORROWER_AND_LOANS, USER_LOGIN_SUCCESS };
