import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import {
  GET_LOAN_DETAILS,
  POST_LOAN_DETAILS,
  GET_LOAN_AND_BORROWERS,
  GET_BORROWER_AND_LOANS,
} from "./CONSTANTS.jsx";

/**
 * Function to fetch all the users.
 */
const getLoanDetails = (lastFourDigitsOfSSN, loanNumber) => {
  console.log("loanLookupService > loanLookup called...");
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
        .get(GET_LOAN_DETAILS() + "/" + lastFourDigitsOfSSN + "/" + loanNumber)
        .then((res) => {
          console.log("loanLookup > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("loanLookup > axios err=", err);
          reject("Error in getAllUsers axios!");
        });
    } catch (error) {
      console.error("in loanLookupService > loanLookup, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

const getLoanAndBorrowers = (body) => {
  console.log("loanLookupService > loanLookup called...");
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
        .post(GET_LOAN_AND_BORROWERS(), body)
        .then((res) => {
          console.log("loanLookup > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("loanLookup > axios err=", err);
          reject("Error in getAllUsers axios!");
        });
    } catch (error) {
      console.error("in loanLookupService > loanLookup, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

const getBorrowerAndLoans = (body) => {
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
        .post(GET_BORROWER_AND_LOANS(), body)
        .then((res) => {
          console.log("loanLookup > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("loanLookup > axios err=", err);
          reject("Error in getAllUsers axios!");
        });
    } catch (error) {
      console.error("in loanLookupService > loanLookup, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

/* post data */
const POSTLOANDETAILS = async (body) => {
  try {
    const response = await fetch(POST_LOAN_DETAILS(), {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify(body),
    });
    const result = await response.json();
    if (result === "Session Expired Pls Login Again") {
      alert(result);
      return [];
    } else {
      //const result=await response.json()
      return result;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export {
  getLoanDetails,
  POSTLOANDETAILS,
  getLoanAndBorrowers,
  getBorrowerAndLoans,
};
