import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Container, Paper, Typography, InputAdornment, TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import SuccessImg from '../../assets/images/success.png'
import './PaymentSuccessPage.css'
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Header from '../CommonComponents/Header'
import Footer from '../CommonComponents/Footer'
import { Link } from "react-router-dom";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.warning.light,

    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles({
  table: {},
});


const options = {
  year: "numeric",
  month: "long",
  weekday: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true // This is the line of code we care about here
  /*
      false: displays 24hs format for time
      true: displays 12, AM and PM format
  */
};

var dateValue = new Date();
let paymentDate = dateValue.toLocaleDateString("en-US", options);

// var paymentDate = dateValue.getFullYear() + "-" + ('0' + parseInt(dateValue.getMonth() + 1)).slice(-2) + "-" + ('0' + dateValue.getDate()).slice(-2) + " " +
//   ('0' + dateValue.getHours()).slice(-2) + ':' + ('0' + dateValue.getMinutes()).slice(-2);

function PaymentSuccessPage(props) {

  var Loandata = props.location.state.responseDetails;
  console.log(props, "Loandata")
  var paymentdetails = Loandata[0].split('&');
  let paymentAmount = Loandata[1]
  let abanumber = Loandata[2]
  let accountno = Loandata[3]
  console.log(paymentdetails, "c")
  var trailingCharsIntactCount = 4;

  accountno = new Array(accountno.length - trailingCharsIntactCount + 1).join('x') + accountno.slice(-trailingCharsIntactCount);


  const classes = useStyles();

  return (
    <Fragment>
      <CssBaseline />
      <Header />
      <Container >
        <Grid container className="" style={{ display: "flex", justifyContent: "center" }} >
          <Grid className=" " item xs={12} sm={6} md={6} lg={5}>
            <div className="Payment_card">

              <img src={SuccessImg} alt="SuccessImg" className="SuccessImg" />

              <div className="msg_para">
                <h2>Success! <br /> We've received your payment <br />

                  ${paymentAmount} </h2>
              </div>

              <Divider />

              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  {/* <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={2}>
                      Loan Lookup Details
                    </StyledTableCell>
                  </TableRow>
                </TableHead> */}
                  <TableBody>
                    <StyledTableRow>
                      <TableCell> Transaction id</TableCell>
                      <TableCell>{paymentdetails[3].split("=")[1]}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Response text </TableCell>
                      <TableCell>{paymentdetails[1].split("=")[1]}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell> ABA routing number</TableCell>
                      <TableCell>{abanumber}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>Check account </TableCell>
                      <TableCell>{accountno}</TableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <TableCell>Payment Date  </TableCell>
                      <TableCell>{paymentDate}</TableCell>
                    </StyledTableRow>

                    <StyledTableRow>

                    </StyledTableRow>

                  </TableBody>
                </Table>
              </TableContainer>
              <div className="backtohomebtn">

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                // onClick={MakePaymentHandler}
                >
                  <Link to="/" className="linkbtn">

                    Back to home
                  </Link>

                </Button>

              </div>


            </div>
          </Grid>
        </Grid>



      </Container>

      <Footer />
    </Fragment>
  )
}

export default PaymentSuccessPage;